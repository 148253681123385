window._ = require("underscore");
window.jQuery = require("jquery");
window.$ = window.jQuery;

require("jstz");

Object.assign(window, require("~/utils/global"));

import React from "react";
import { createRoot } from "react-dom/client";
import Root from "./root";
import { initializeSentry } from "./sentry";

document.addEventListener("DOMContentLoaded", function (event) {
  initializeSentry();
  const target = document.getElementById("main");
  createRoot(target).render(<Root />);
});
